import dynamic from "next/dynamic";
import { Box, IconButton } from "@mui/material";
import { CopyAll as CopyIcon } from "@mui/icons-material";
import theme from "react-syntax-highlighter/dist/esm/styles/hljs/atom-one-light";

const SyntaxHighlighter = dynamic(() => import("react-syntax-highlighter"));
const Markdown = dynamic(() => import("react-markdown"));

import { useStore } from "../../service/mobx";

export const RenderMarkdownCode = ({ md }) => (
  <Markdown components={{ code: Code }}>{md}</Markdown>
);
export default function Code({
  children: code,
  className,
  node,
  dark = true,
  ...rest
}) {
  const language = className?.split("language-")[1];

  return language ? (
    <Box
      sx={theme => ({
        minHeight: 56,
        p: 1,
        py: dark ? 2 : undefined,
        position: "relative",
        border: dark ? undefined : 1,
        borderColor: dark ? undefined : "outline.variant",
        bgcolor: dark ? "dark.surface.container.lowest" : "#f9fafb",
        borderRadius: theme.shape.md.round,
        color: "dark.surface.on.color",
        "& > pre": {
          m: 0,
          pl: "12px !important"
        },
        "& .hljs-keyword, .hljs-built_in": {
          color: "dark.primary.color"
        },
        "& .hljs-comment": {
          color: "dark.secondary.color"
        },
        "& .hljs-string, .hljs .hljs-params": {
          color: "dark.tertiary.color"
        },
        "& .react-syntax-highlighter-line-number": {
          color: "dark.secondary.container"
        },
        "& *": {
          typography: "code",
          borderBottomLeftRadius: "inherit",
          borderBottomRightRadius: "inherit",
          fontSize: { compact: 11, expanded: 12 }
        }
      })}
    >
      <CopyBar code={code} dark={dark} />
      <SyntaxHighlighter
        wrapLongLines
        lineProps={{
          style: { wordBreak: "break-all", whiteSpace: "pre-wrap" }
        }}
        language={language}
        style={dark ? undefined : theme}
        useInlineStyles={dark ? false : undefined}
      >
        {code}
      </SyntaxHighlighter>
    </Box>
  ) : (
    <code {...rest} className={className}>
      {code}
    </code>
  );
}

function CopyBar({ dark, code }) {
  const { snackbar } = useStore();

  return (
    <IconButton
      sx={{
        top: 8,
        right: 8,
        position: "absolute",
        bgcolor: dark ? "rgba(0,0,0,0.8)" : "rgba(255,255,255,0.8)"
      }}
      onClick={() => {
        navigator.clipboard.writeText(code);
        snackbar.notify({ text: "Copied to clipboard" });
      }}
    >
      <CopyIcon
        sx={{
          width: 24,
          height: 24,
          color: dark ? "surface.inverse.on" : "surface.on.variant"
        }}
      />
    </IconButton>
  );
}
