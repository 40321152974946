import { useEffect, useState } from "react";
import { Box } from "@mui/material";

import { useStore, observer } from "../../../../service/mobx";
import { RenderMarkdownCode } from "../../../../component/Markdown/Code";
import Tabs from "../../../../component/Tabs";
import ButtonText from "../../../../component/Button/Text";

function Code({ model, input }) {
  const [tabs] = useState([
    { label: "python" },
    { label: "javascript" },
    { label: "julia" }
  ]);
  const [index, setIndex] = useState(() => {
    try {
      return sessionStorage.getItem("preferred-lang") || "0";
    } catch {
      return "0";
    }
  });
  const [code, setCode] = useState("");
  const { device, user } = useStore();
  const key = user.profile?.key;

  useEffect(() => {
    const language = tabs[index].label;
    const userKey = key ?? "YOUR KEY";
    const { modelId } = model;
    let code;
    const takesJSON = Array.isArray(input) || input?.constructor === Object;
    let runMap = input
      ? takesJSON
        ? JSON.stringify(input, undefined, 2)
        : `"${input}"`
      : "";
    const juliaReduceToDict = obj =>
      Object.entries(obj)
        .reduce(
          (string, [key, value]) =>
            (string += `"${key}" => ${
              Array.isArray(value) ? JSON.stringify(value) : `"${value}"`
            }, `),
          " Dict("
        )
        .slice(0, -2) + ") ";

    switch (language) {
      case "python": {
        code = `
          from bytez import Bytez
          client = Bytez("${userKey}");

          # 1) - select
          model = client.model("${modelId}")

          # 2) - load
          model.load()
          
          # 3) - run
          output = model.run(${runMap})
          
          print(output)
      `;
        break;
      }
      case "javascript": {
        code = `
          import Bytez from "bytez.js";
          const client = new Bytez("${userKey}");
    
          // 1) - select
          const model = client.model("${modelId}")
  
          // 2) - load
          await model.load()
          
          // 3) - run
          const output = await model.run(${runMap})
          
          console.log(output)
        `;
        break;
      }
      case "julia": {
        code = `
          using Bytez
          client = Bytez.init("${userKey}");

          # 1) - select
          model = client.model("${modelId}")

          # 2) - load
          model.load()
          
          # 3) - run
          output = model.run(${
            input
              ? takesJSON
                ? Array.isArray(input)
                  ? input.map(juliaReduceToDict)
                  : juliaReduceToDict(input)
                : `"${input}"`
              : ""
          })
          
          println(output)
        `;
        break;
      }
    }

    setCode(`\`\`\`${language}\n${code.trim().replace(/ {10}/g, "")}\n\`\`\``);
    sessionStorage.setItem("preferred-lang", index);
  }, [tabs, model, key, index, input]);

  return (
    <Box
      p={{ compact: 0, expanded: 2 }}
      pt={{ compact: 1, expanded: 2 }}
      bgcolor="surface.container.low"
    >
      <Tabs
        noOutline
        tabs={tabs}
        initial={index}
        onChange={setIndex}
        variant={device.isPhone ? "scrollable" : undefined}
      />
      <Box
        maxHeight={500}
        sx={{ overflowX: "hidden", overflowY: "overlay !important" }}
      >
        <RenderMarkdownCode md={code} />
        <ButtonText
          fullWidth
          label="Read docs"
          component="a"
          target="_blank"
          href="https://github.com/Bytez-com/docs"
        />
      </Box>
    </Box>
  );
}

export default observer(Code);
