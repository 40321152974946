"use client";
import { Fragment, useEffect, useState } from "react";
import dynamic from "next/dynamic";
// import Image from "next/image";
import {
  Stack,
  CardHeader,
  // Typography,
  // CardMedia,
  Box,
  Unstable_Grid2 as Grid
  // Slider,
  // IconButton,
  // Collapse,
  // TextField,
  // useTheme
} from "@mui/material";
import {
  Download as DownloadIcon,
  Star as LikesIcon
} from "@mui/icons-material";
import useSWR from "swr";

import { useStore } from "../../../service/mobx";
// import Star from "../../Star";
import FilledCard from "../Filled";
import { ModelIcon } from "../../Icon/Model";
import Widget from "./Widget";
import ToolTip from "../../Tooltip";
import { fetcher } from "../../../service/graph";
import NextAvatar from "../../Avatar";
// import StackOverflow from "../../StackOverflow";
// import Popup from "../../Popup";
// import ChipAssist from "../../Chip/Assist";
// import { TaskSize1 } from "../Task";
// import { PaperCardSize1 } from "../Paper";

const Popup = dynamic(() => "../../Popup");
const ChipAssist = dynamic(() => import("../../Chip/Assist"));
const GameBadge = dynamic(() => import("../../GameBadge"));

// const prices = {
//   micro: 0.0005844,
//   xs: 0.0008944,
//   sm: 0.0043467,
//   md: 0.0051129,
//   lg: 0.0086933,
//   xl: 0.0148333,
//   xxl: 0.0364111,
//   super: 0.0455111
// };

export function ModelCardSize1({ model }) {
  // const Icon = user.following.has(task) ? OnIcon : OffIcon;

  return (
    <Popup maxWidth={640} Component={() => <ModelCardSize2 model={model} />}>
      <div>
        <ChipAssist
          // StartIcon={Icon}
          label={model?.alias}
          onClick={() => {
            // analytics.track.event
            // user
          }}
        />
      </div>
    </Popup>
  );
}
export const ModelCardSize2 = ({ model, disabled }) => (
  <Card thin disabled={disabled} model={model}>
    <Header published params task size="small" model={model} />
  </Card>
);
export function ModelCardSize3({ model, disabled }) {
  const avatar = useAvatar(model);

  // console.log(model);
  //
  return (
    <Card disabled={disabled} model={model}>
      <Header
        avatar={avatar}
        noGameBadge
        published
        params
        task
        modelSize
        size="small"
        model={model}
      />
    </Card>
  );
}
export function ModelCardSize4({ model, task }) {
  const avatar = useAvatar(model);
  // console.log(model.name);

  //
  return (
    <Card model={model}>
      <Header
        noGameBadge
        // noGameBadge={noGameBadge}
        avatar={avatar}
        published
        params
        modelSize
        likes
        task={task}
        downloads
        size="large"
        model={model}
      />
    </Card>
  );
}
export function ModelCardSize5({ model }) {
  // const src = `https://replicate.delivery/pbxt/IJEPmgAlL2zNBNDoRRKFegTEcxnlRhoQxlNjPHSZEy0pSIKn/gg_bridge.jpeg`;

  //
  return (
    <Card model={model}>
      <Grid container sx={{ maxWidth: 640 }}>
        <Grid compact={6} pr={2}>
          {/* <CardMedia
            component="img"
            src={src}
            sx={{
              width: "100%",
              borderRadius: theme => theme.shape.lg.top
              // objectFit: "contain"
            }}
          />
          <TextField
            fullWidth
            placeholder={`Try ${model.name}`}
            sx={theme => ({
              mt: 2,
              height: 48,
              "& fieldset": { border: "none" },
              bgcolor: "surface.container.high",
              borderRadius: theme.shape.round
            })}
            inputProps={{
              sx: {
                height: 16,
                borderRadius: theme => theme.shape.round,
                "&:focus": {
                  bgcolor: "surface.container.highest"
                }
              }
            }}
          /> */}
          <Widget small model={model} />
        </Grid>
        <Grid compact={6} display="flex" height="auto" flexDirection="column">
          {/* <Header license publisher runs model={model} /> */}
          <Header
            noStar
            published
            params
            task
            modelSize
            size="large"
            model={model}
          />

          {/* <Performance model={model} /> */}
          <Stack direction="row" justifyContent="space-between">
            {/* <StackOverflow
              Card={TaskSize1}
              cards={model.tasks}
              entityName="tag"
            /> */}
            {/* <PaperCardSize1 paper={model.paper} /> */}
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
}

function Card({ children, disabled, model, onClick, ...cardProps }) {
  const { analytics } = useStore();

  return (
    <FilledCard
      prefetch
      href={`/docs/${model?.paper?.publisher ?? model?.org}/${
        model?.paper?.paperID ?? model?.displayName
      }/model`}
      disabled={disabled}
      tone="surface.container.low"
      onClick={() => {
        onClick?.();
        analytics.track.event("Model Click", { modelId: model.name });
      }}
      {...cardProps}
    >
      {children}
    </FilledCard>
  );
}

function Header({
  model,
  Status,
  size = "medium",
  noStar,
  noGameBadge,
  ...subheaderProps
}) {
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    if (typeof window !== "undefined") {
      const imgUrl = model.repo?.hfOwner?.avatar;

      setAvatar(
        `/api/model/avatar?org=${model.org}${
          imgUrl ? `&imgUrl=${btoa(imgUrl)}` : ""
        }`
      );
    }
  }, [model]);
  // const { utilities } = useStore();
  // const [noImg, setNoImg] = useState(false);
  // const task = model.tasks?.[0]?.name;
  /*
   : (
          <Image
            width={40}
            height={40}
            alt={task}
            src={`https://cdn.bytez.com/images/v2/${encodeURIComponent(
              task
            )}.png`}
            sizes="(max-width: 1600px) 320px, (max-width: 1200px) 475px, 500px"
            onError={() => setNoImg(true)}
          />
        )
  
  */
  //  const colors = ["secondary", "secondary", "tertiary"];

  //   const tertiary = [
  //     // 0.009 < model.delta ? (
  //     //   <Typography
  //     //     variant="labelMd"
  //     //     color={theme => theme.palette.colors.success[40]}
  //     //   >{`+${model.delta.toFixed(1)}%`}</Typography>
  //     // ) : undefined,

  //     // license ? model.license : undefined,
  //     // runs ? `${model.runs?.toLocaleString()} runs` : undefined,
  //     // model.params ? `${utilities.formatNumber(model.params)} params` : undefined,
  //     model.meterSize
  //   ].filter(defined => defined);
  return (
    <CardHeader
      avatar={
        noGameBadge !== true && model.repo?.scoreTrending ? (
          <GameBadge scoreTrending={model.repo?.scoreTrending} />
        ) : (
          <NextAvatar
            size={40}
            alt={model.org}
            src={avatar}
            variant="rounded"
            fallback={<ModelIcon sx={{ color: "surface.on.variant" }} />}
          />
        )
      }
      title={model.displayName}
      // title={
      //   <>
      //     <Stack
      //       direction="row"
      //       justifyContent="space-between"
      //       alignItems="center"
      //     >
      //       <div>{model.displayName}</div>
      //       <Stack
      //         useFlexGap
      //         spacing={0.5}
      //         pt={1}
      //         width="fit-content"
      //         direction="row"
      //         alignItems="center"
      //       >
      //         {tertiary.map((content, index) => (
      //           <Fragment key={index}>
      //             <Box
      //               component="span"
      //               typography={size === "small" ? "labelSm" : "labelMd"}
      //               bgcolor={`${colors[index]}.container`}
      //               color={`${colors[index]}.color`}
      //               px={1}
      //               borderRadius={1000}
      //             >
      //               {content}
      //             </Box>
      //           </Fragment>
      //         ))}
      //       </Stack>
      //     </Stack>
      //   </>
      // }
      titleTypographyProps={{
        variant: "headlineMd",
        color: "surface.on.color",
        sx: theme => theme.typography.lineClamp(3),
        typography: {
          compact: size === "small" ? "labelMd" : "titleMd",
          expanded:
            size === "small"
              ? "labelLg"
              : size === "large"
              ? "titleLg"
              : size === "xl"
              ? "titleLg"
              : "titleSm",
          large:
            size === "small"
              ? "labelLg"
              : size === "large"
              ? "titleMd"
              : size === "xl"
              ? "headlineMd"
              : "titleMd"
        }
      }}
      // subheader={`${model.org}`}
      subheaderTypographyProps={{
        color: "secondary.color",
        typography: {
          compact: "bodySm",
          expanded:
            size === "xl" ? "bodyLg" : size === "small" ? "bodySm" : "bodyMd"
        }
      }}
      subheader={<SubHeader model={model} size={size} {...subheaderProps} />}
      // action={
      //   noStar ? null : (
      //     <Box px={1}>
      //       <Star entity={model} size={size} />
      //     </Box>
      //   )
      // }
      action={
        model.badge ? (
          <Box
            typography="labelMd"
            ml={{ compact: 1, expanded: 0 }}
            px={1}
            py={0.5}
            bgcolor="colors.info.90"
            color="colors.info.10"
            borderRadius={theme => theme.shape.xs.round}
          >
            {model.badge}
          </Box>
        ) : null
      }
      // sx={theme => ({
      //   p: 0
      //   // "& img": {
      //   //   borderRadius: theme.shape.xs.round
      //   // }
      // })}
      sx={{ p: 0, alignItems: "flex-start" }}
    />
  );
}
function SubHeader({
  model,
  likes = false,
  downloads = false,
  // size,
  // modelSize,
  org = true,
  params = false,
  published = true,
  // publisher = false,
  // runs = false,
  // license = false,
  // delta = false,
  task = false
}) {
  const { utilities } = useStore();
  const secondary = [
    org ? model.org : undefined,
    task ? model.tasks?.[0]?.name : undefined,
    (published || true) &&
    (model.paper?.published ||
      model.repo?.created ||
      model.updated ||
      model.created)
      ? utilities.paperTime(
          model.paper?.published ||
            model.repo?.created ||
            model.updated ||
            model.created
        )
      : undefined,
    params && model.params
      ? `${utilities.formatNumber(model.params)} params`
      : undefined
  ].filter(defined => defined);
  // const tertiary = [
  //   // 0.009 < model.delta ? (
  //   //   <Typography
  //   //     variant="labelMd"
  //   //     color={theme => theme.palette.colors.success[40]}
  //   //   >{`+${model.delta.toFixed(1)}%`}</Typography>
  //   // ) : undefined,

  //   license ? model.license : undefined,
  //   // runs ? `${model.runs?.toLocaleString()} runs` : undefined,
  // params && model.params
  //   ? `${utilities.formatNumber(model.params)} params`
  //   : undefined,
  //   modelSize ? model.meterSize : undefined
  // ].filter(defined => defined);

  return (
    <>
      <Stack
        useFlexGap
        py={0.5}
        direction="row"
        alignItems="center"
        flexWrap="wrap"
      >
        {secondary.map((content, index, array) => (
          <Fragment key={index}>
            <Box
              component="span"
              color="secondary.color"
              // variant={size === "small" ? "labelSm" : "labelMd"}
              // bgcolor={`${colors[index]}.container`}
              // color={`${colors[index]}.color`}
              // px={1}
              borderRadius={1000}
            >
              {content}
            </Box>
            {index + 1 === array.length ? undefined : <Box mx={0.5}>·</Box>}
          </Fragment>
        ))}
      </Stack>
      {likes || downloads || model.badge ? (
        <Stack pt={1} direction="row" spacing={1}>
          {likes && model.repo?.likes ? (
            <ToolTip title="Likes" placement="left">
              <Stack
                spacing={0.5}
                typography="labelMd"
                pl={1}
                pr={1.25}
                py={0.5}
                direction="row"
                alignItems="center"
                borderRadius={theme => theme.shape.xs.round}
                bgcolor="colors.neutralVariant.90"
                color="colors.neutralVariant.10"
              >
                <LikesIcon
                  sx={{ width: 12, height: 12, color: "surface.on.variant" }}
                />
                <span>{utilities.formatNumber(model.repo.likes)}</span>
              </Stack>
            </ToolTip>
          ) : null}
          {downloads && model.repo?.downloads ? (
            <ToolTip title="Downloads" placement="right">
              <Stack
                spacing={0.5}
                typography="labelMd"
                pl={1}
                pr={1.25}
                py={0.5}
                direction="row"
                alignItems="center"
                borderRadius={theme => theme.shape.xs.round}
                bgcolor="colors.neutralVariant.90"
                color="colors.neutralVariant.10"
              >
                <DownloadIcon
                  sx={{ width: 16, height: 16, color: "surface.on.variant" }}
                />
                <span>{utilities.formatNumber(model.repo.downloads)}</span>
              </Stack>
            </ToolTip>
          ) : null}
        </Stack>
      ) : null}
    </>
  );
}
function useAvatar(model) {
  const [avatar, setAvatar] = useState(null);
  const [type, setType] = useState();
  const { data, isLoading } = useSWR(
    avatar === undefined && type !== undefined
      ? `https://huggingface.co/api/${type}/${model.org}/avatar`
      : null,
    fetcher
  );

  useEffect(() => {
    setAvatar(
      model.repo?.hfOwner?.avatar ??
        sessionStorage.getItem(`avatar-${model.org}`) ??
        undefined
    );
  }, [model]);

  useEffect(() => {
    if (avatar === undefined && isLoading === false) {
      const avatar = data?.avatarUrl;

      if (avatar) {
        setAvatar(avatar);
        sessionStorage.setItem(`avatar-${model.org}`, avatar);
      } else {
        setType(type => (type === undefined ? "users" : "organizations"));
      }
    }
  }, [model.org, avatar, isLoading, data?.avatarUrl]);

  // if (model.org === "meta-llama") {
  //   console.log({
  //     saved: model.repo?.hfOwner?.avatar,
  //     url: `https://huggingface.co/api/${type}/${model.org}/avatar`,
  //     realAvatar: avatar
  //   });
  // }

  return avatar;
}
// const Performance = ({ model }) =>
//   model.aggregate?.performanceScore || model.cost ? (
//     <Grid container spacing={1} pl={model.status ? 6 : undefined} width="100%">
//       <Grid compact="auto">
//         <InfoCard
//           label="performance"
//           bounds={[0, 26, 75, 100]}
//           value={model.aggregate?.performanceScore}
//         />
//       </Grid>
//       <Grid compact="auto">
//         <InfoCard
//           reverse
//           bounds={[0, 1.5, 3.2, 32]}
//           label="cost"
//           value={model.cost}
//         />
//       </Grid>
//     </Grid>
//   ) : null;

// const MODEL = {
//   id: "eyJwYXBlcklEIjoiMjMwOS4wNzkxNSIsInB1Ymxpc2hlciI6ImFyeGl2In0=",
//   paper: [{ publisher: "arxiv", paperID: "2309.07915" }],
//   created: "2023-09-14T17:59:17.000Z",
//   name: "stable-diffusion-xl-refiner",
//   org: "stability",
//   cost: "2.30",
//   runs: 20232,
//   license: "mit",
//   publisher: "cvpr",
//   tasks: ["text generation"],
//   aggregate: {
//     performancePosition: 3,
//     performanceScore: 82,
//     costPosition: 10,
//     efficiencyPosition: 32
//   },
//   leaderboards: [
//     {
//       name: "minst",
//       performance: {
//         position: 10,
//         score: 72
//       },
//       cost: {
//         position: 10
//       },
//       efficiencyPosition: 32
//     },
//     {
//       name: "cif6",
//       performance: {
//         position: 100,
//         score: 21
//       },
//       cost: {
//         position: 100
//       },
//       efficiencyPosition: 32
//     }
//   ]
// };
