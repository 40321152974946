import dynamic from "next/dynamic";
import { CircularProgress } from "@mui/material";

import { style } from "./";

const Filled = dynamic(() => import("./Filled"));
const Outlined = dynamic(() => import("./Outlined"));
const Text = dynamic(() => import("./Text"));
const Tonal = dynamic(() => import("./Tonal"));

export default function ButtonLoading({
  variant = "filled",
  loading = false,
  disabled = false,
  label,
  labelLoading,
  ...props
}) {
  const Component =
    {
      filled: Filled,
      outlined: Outlined,
      text: Text,
      tonal: Tonal
    }[variant] || Filled;

  if (loading) {
    const end = !!props.IconEnd;

    props[`Icon${end ? "End" : "Start"}`] = () => (
      <CircularProgress color="inherit" size={18} sx={style(end, true)} />
    );
  }

  return (
    <Component
      disabled={disabled || loading}
      label={(loading && labelLoading) || label}
      {...props}
    />
  );
}
