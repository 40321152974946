"use client";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Stack,
  List,
  IconButton,
  CardActionArea
} from "@mui/material";
import {
  Add as AddIcon,
  Remove as DeleteIcon,
  Audiotrack as AudioIcon
} from "@mui/icons-material";

import { useStore, observer } from "../../../service/mobx";
import ButtonText from "../../Button/Text";
import { ListItem } from "../../List/ListItem";
import ToolTip from "../../Tooltip";
import ButtonOutlined from "../../Button/Outlined";

export default function useIO(model) {
  const [io] = useState(() => {
    const map = {
      // audio in
      "audio-classification": [
        "Classify audio",
        InputAudio,
        OutputJSON,
        ExamplesAudio
      ], // works, but wav2vec2_owl_classifier_sew_d says "text"
      "automatic-speech-recognition": [
        "Speech to text",
        InputAudio,
        OutputText,
        ExamplesAudio
      ], // works but only returns "" or "a"
      //
      // image in
      //
      "depth-estimation": [
        "Estimate image depth",
        InputImage,
        OutputDepthEstimation,
        ExamplesInputImage
      ], // works
      "object-detection": [
        "Object detection",
        InputImage,
        OutputJSON,
        ExamplesInputImage
      ], // works, but models can have input quirks "text"
      "image-classification": [
        "Image classification",
        InputImage,
        OutputJSON,
        ExamplesInputImage
      ], // works
      "image-feature-extraction": [
        "Image to vector",
        InputImage,
        OutputJSON,
        ExamplesInputImage
      ], // works
      "image-segmentation": [
        "Segmentation",
        InputImage,
        OutputJSON,
        ExamplesInputImage
      ], // works, but "text"
      "image-to-text": [
        "Generate text",
        InputImage,
        OutputText,
        ExamplesInputImage
      ], // works
      "mask-generation": ["", InputImage, OutputJSON, ExamplesInputImage, true], // fails, Incorrect padding, Invalid base64-encoded string: number of data characters (69) cannot be 1 more than a multiple of 4
      // text in
      "fill-mask": ["Fill in the blank", InputText, OutputJSON], //works but ===> "text"
      "feature-extraction": ["Generate vectors", InputText, OutputJSON], // works ======> but model inputs may vary
      "sentence-similarity": ["Generate vectors", InputText, OutputJSON], // works ====> but models inputs may vary
      summarization: ["Summarize text", InputText, OutputText], // works
      "text-classification": ["Classify text", InputText, OutputJSON], // fail? the outputs look static, and make no sense
      "text-generation": ["Generate text", InputText, OutputText], // works
      "text-to-audio": [
        "Generate audio",
        InputText,
        OutputAudio,
        undefined,
        true
      ], // fail
      "text-to-image": ["Generate images", InputText, OutputImg], // works
      "text-to-speech": [
        "Generate speech",
        InputText,
        OutputAudio,
        undefined,
        true
      ], // fail
      "text-to-video": [
        "Generate videos",
        InputText,
        OutputVideo,
        undefined,
        true
      ], // fail
      "text2text-generation": ["Generate text", InputText, OutputText], // works?
      "token-classification": ["Classify tokens", InputText, OutputJSON], // works
      translation: ["Translate text", InputText, OutputText], // works
      //
      // json in
      chat: ["Chat", InputChat], // works
      "question-answering": ["Q&A", InputQuestionAnswering, OutputJSON], // works
      "document-question-answering": [
        "Document Q&A",
        InputVisualQA,
        OutputJSON,
        ExamplesInputQADocs
      ], // works
      "visual-question-answering": [
        "Visual Q&A",
        InputVisualQA,
        OutputJSON,
        ExamplesInputQAVisual
      ], // "text" error again
      "zero-shot-classification": [
        "Classify text",
        InputZeroShotClassification,
        OutputJSON
      ], // works
      "zero-shot-image-classification": [
        "Classify images",
        InputZeroShotImageClassification,
        OutputJSON,
        ExamplesInputQAVisual
      ], // works
      "zero-shot-object-detection": [
        "Detect objects",
        InputZeroShotImageClassification,
        OutputJSON,
        ExamplesInputQAVisual
      ], // works
      // null in
      "unconditional-image-generation": [
        "Generate images",
        undefined,
        OutputImg
      ], // works
      // video in
      "video-classification": [undefined, undefined, undefined, undefined, true] // fails bytez.js testing
    };
    const noOp = () => null;
    const [
      label,
      Input = noOp,
      Output = noOp,
      Examples = noOp,
      comingSoon = false
    ] = map?.[model.task] ?? [];

    return { label, Input, Output, Examples, comingSoon };
  });

  return io;
}
//
// inputs
//
function InputText({ input, setInput, task, loading }) {
  const noInput = input === undefined;

  useEffect(() => {
    if (noInput) {
      const examples = {
        summarization:
          "The tower is 324 metres (1,063 ft) tall, about the same height as an 81-storey building, and the tallest structure in Paris. Its base is square, measuring 125 metres (410 ft) on each side. It was the first structure to reach a height of 300 metres. Excluding transmitters, the Eiffel Tower is the second tallest free-standing structure in France after the Millau Viaduct.",
        "text-classification": "Deep learning is wonderful",
        "text-generation": "Once upon a time",
        "text-to-image": "A cat in a hat",
        "fill-mask": "Hello, my name is <mask>",
        translation: "Good morning",
        "feature-extraction":
          "John Smith went to London to sip lapsang souchong"
      };
      setInput(examples[task] || "Once upon a time");
    }
  }, [setInput, noInput, task]);

  return (
    <InputTextField
      multiline
      minRows={4}
      value={input}
      disabled={loading}
      onChange={event => setInput(event.target.value)}
    />
  );
}
function InputAudio({ input, setInput, loading }) {
  const [valid, setValid] = useState();
  const ref = useRef();
  const handleInputChange = useCallback(
    event => {
      // Check if the input is a file
      if (event.target.files) {
        const [file] = event.target.files;
        const reader = new FileReader();

        reader.onload = event => setInput(event.target.result.slice(22));
        reader.readAsDataURL(file);
        setValid(true);
      } else {
        try {
          var valid = true;
          new URL(event.target.value);
        } catch {
          valid = false;
        } finally {
          setValid(valid);
          setInput(event.target.value);
        }
      }
    },
    [setInput]
  );

  // console.log({ input });
  // https://huggingface.co/datasets/huggingfacejs/tasks/resolve/main/audio-classification/audio.wav
  return (
    <>
      <InputTextField
        value={input}
        disabled={loading}
        onChange={handleInputChange}
        placeholder="Enter URL or upload a .wav file"
        helperText={
          valid || valid === undefined
            ? undefined
            : "Invalid ULR. It should look something like https://url.com/audio.wav"
        }
      />
      <ButtonText
        fullWidth
        disabled={loading}
        label="Upload audio file"
        onClick={() => ref.current.click()}
      />
      <input
        ref={ref}
        type="file"
        accept=".wav, audio/wav"
        onChange={handleInputChange}
        style={{ display: "none" }}
      />
    </>
  );
}
function InputImage({ input, setInput, loading, ...props }) {
  const [valid, setValid] = useState();
  const ref = useRef();
  const handleInputChange = useCallback(
    event => {
      // Check if the input is a file
      if (event.target.files) {
        const [file] = event.target.files;
        const reader = new FileReader();

        reader.onload = event => setInput(event.target.result.slice(22));
        reader.readAsDataURL(file);
        setValid(true);
      } else {
        try {
          var valid = true;
          new URL(event.target.value);
        } catch {
          valid = false;
        } finally {
          setValid(valid);
          setInput(event.target.value);
        }
      }
    },
    [setInput]
  );

  // console.log({ input });
  // https://huggingface.co/datasets/huggingfacejs/tasks/resolve/main/audio-classification/audio.wav
  return (
    <>
      <InputTextField
        value={input}
        disabled={loading}
        onChange={handleInputChange}
        placeholder="Upload an image or insert a base64 png"
        helperText={
          valid || valid === undefined
            ? undefined
            : "Invalid ULR. It should look something like https://url.com/image.png"
        }
        {...props}
      />
      <ButtonText
        fullWidth
        disabled={loading}
        label="Upload image"
        onClick={() => ref.current.click()}
      />
      <input
        ref={ref}
        type="file"
        accept=".png, image/png"
        onChange={handleInputChange}
        style={{ display: "none" }}
      />
    </>
  );
}
function InputQuestionAnswering({ input, setInput, loading }) {
  useEffect(() => {
    if (input === undefined) {
      setInput({
        context: "My name is Simon and I live in London",
        question: "Where do I live?"
      });
    }
  }, [setInput, input]);

  return (
    <>
      <InputTextField
        label="Context"
        loading={loading}
        value={input?.context}
        onChange={event =>
          setInput(input => ({ ...input, context: event.target?.value ?? "" }))
        }
      />
      <InputTextField
        label="Question"
        loading={loading}
        value={input?.question}
        onChange={event =>
          setInput(input => ({ ...input, question: event.target?.value ?? "" }))
        }
      />
    </>
  );
}
function InputVisualQA({ input, setInput, loading, task }) {
  useEffect(() => {
    if (input === undefined) {
      setInput({
        question:
          task === "document-question-answering"
            ? "What's the total cost?"
            : "What is this a picture of?",
        image: ""
      });
    }
  }, [setInput, task, input]);

  return (
    <>
      <InputTextField
        label="Question"
        disabled={loading}
        value={input?.question}
        onChange={event =>
          setInput(input => ({ ...input, question: event.target?.value ?? "" }))
        }
      />
      <InputImage
        label="Image context"
        loading={loading}
        value={input?.image}
        setInput={image => setInput(input => ({ ...input, image }))}
      />
    </>
  );
}
function InputZeroShotClassification({ input, setInput, loading, task }) {
  useEffect(() => {
    if (input === undefined) {
      setInput({
        text: "Ninja turtles are cool",
        candidate_labels: ["positive", "negative"]
      });
    }
  }, [setInput, task, input]);

  return (
    <>
      <InputTextField
        label="text"
        disabled={loading}
        value={input?.text}
        onChange={event =>
          setInput(input => ({ ...input, text: event.target?.value ?? "" }))
        }
      />
      <InputTextField
        label="classes"
        disabled={loading}
        value={input?.candidate_labels?.toString()}
        onChange={event =>
          setInput(input => ({
            ...input,
            candidate_labels: event.target?.value?.split(",") ?? ""
          }))
        }
      />
    </>
  );
}
function InputZeroShotImageClassification({ input, setInput, loading, task }) {
  useEffect(() => {
    if (input === undefined) {
      setInput({
        candidate_labels: ["squid", "octopus", "human", "cat"],
        image: ""
      });
    }
  }, [setInput, task, input]);

  return (
    <>
      <InputTextField
        label="classes"
        disabled={loading}
        value={input?.candidate_labels?.toString()}
        onChange={event =>
          setInput(input => ({
            ...input,
            candidate_labels: event.target?.value?.split(",") ?? ""
          }))
        }
      />
      <InputTextField
        label="image"
        disabled={loading}
        value={input?.image}
        onChange={event =>
          setInput(input => ({ ...input, image: event.target?.value ?? "" }))
        }
      />
    </>
  );
}
function InputChat({ input, setInput, loading, output }) {
  useEffect(() => {
    if (input === undefined) {
      setInput([
        {
          role: "system",
          content:
            "You are a friendly chatbot who always responds in the style of a pirate"
        },
        {
          role: "user",
          content: "How many helicopters can a human eat in one sitting?"
        }
      ]);
    }
  }, [setInput, input]);

  useEffect(() => {
    if (output) {
      setInput(output);
    }
  }, [setInput, output]);

  return (
    <List
      sx={{
        pt: 2,
        bgcolor: "dark.surface.color",
        borderRadius: theme => theme.shape.md.round
      }}
    >
      {input?.map?.(({ role, content }, index) => (
        <ListItem
          key={index}
          dark
          disabled={loading}
          sx={{
            height: "100%",
            maxHeight: undefined,
            alignItems: "flex-start"
          }}
          leading={{
            Icon: () => (
              <ToolTip placement="left" title="Swap message role">
                <ButtonText
                  fullWidth
                  label={role}
                  onClick={
                    loading
                      ? undefined
                      : () =>
                          setInput(messages => {
                            messages[index].role =
                              role === "system"
                                ? "user"
                                : role === "user"
                                ? "assistant"
                                : index === 0
                                ? "system"
                                : "user";

                            return [...messages];
                          })
                  }
                  sx={{
                    width: 80,
                    typography: "labelMd",
                    color: "dark.surface.on.color",
                    bgcolor: "dark.surface.container.highest"
                  }}
                />
              </ToolTip>
            )
          }}
          line1Sx={{ width: "100%" }}
          line1={
            <TextField
              fullWidth
              multiline
              value={content}
              placeholder={`Fill in your ${role} message here`}
              variant="standard"
              onChange={event => {
                setInput(messages => {
                  messages[index].content = event.target.value;

                  return [...messages];
                });
              }}
              InputProps={{
                readOnly: loading,
                disableUnderline: true,
                sx: {
                  overflow: "auto",
                  typography: "bodyLg",
                  color: "dark.surface.on.color"
                }
              }}
            />
          }
          trailing={{
            Icon: ({ sx }) => (
              <IconButton
                onClick={() => {
                  input.splice(index, 1);
                  setInput([...input]);
                }}
              >
                <DeleteIcon sx={sx} />
              </IconButton>
            )
          }}
        />
      ))}
      <ListItem
        dark
        disabled={loading}
        leading={{ Icon: AddIcon }}
        line1="Add message"
        onClick={() =>
          setInput([
            ...input,
            {
              role:
                input[input.length - 1]?.role === "user" ? "assistant" : "user"
            }
          ])
        }
      />
    </List>
  );
}
export const InputTextField = ({ value, sx, ...props }) => (
  <TextField
    label="input"
    value={value || ""}
    variant="filled"
    inputProps={{
      sx: {
        color: "surface.on.color",
        typography: { compact: "bodySm", expanded: "bodyLg" }
      }
    }}
    InputProps={{ sx: { bgcolor: "unset" } }}
    sx={theme => ({
      maxHeight: 300,
      overflow: "auto",
      bgcolor: "surface.container.highest",
      borderRadius: theme.shape.lg.top,
      "& ::before": {
        borderBottomColor: `${theme.palette.outline.variant} !important`
      },
      "& :hover::before": {
        borderBottomColor: `${theme.palette.error.container} !important`
      },
      "& label, & .Mui-focused": {
        color: theme => `${theme.palette.secondary.color} !important`
      },
      ...sx
    })}
    {...props}
  />
);

//
// outputs
//
const OutputText = ({ output }) =>
  output ? <OutputTextTemplate multiline value={output} /> : null;
const OutputJSON = ({ output, task }) =>
  output ? (
    <OutputTextTemplate
      multiline
      value={JSON.stringify(
        output,
        undefined,
        new Set([
          "sentence-similarity",
          "feature-extraction",
          "depth-estimation",
          "image-feature-extraction"
        ]).has(task)
          ? undefined
          : 2
      )}
    />
  ) : null;
const OutputImg = ({ output }) =>
  output ? (
    <OutputWrapper>
      <Box
        component="img"
        src={`data:image/png;base64,${output}`}
        sx={{ maxWidth: "100%", borderRadius: theme => theme.shape.md.round }}
        onLoad={({ target }) => {
          target.style.aspectRatio = `${target.naturalWidth} / ${target.naturalHeight}`;
        }}
      />
    </OutputWrapper>
  ) : null;
const OutputAudio = ({ output }) =>
  output ? (
    <OutputWrapper>
      <audio controls>
        <source type="audio/wav" src={`data:audio/wav;base64,${output}`} />
      </audio>
    </OutputWrapper>
  ) : null;
const OutputVideo = ({ output }) =>
  output ? (
    <OutputWrapper>
      <video controls>
        <source type="video/mp4" src={`data:video/mp4;base64,${output}`} />
      </video>
    </OutputWrapper>
  ) : null;

const OutputTextTemplate = ({ sx, ...props }) => (
  <Box
    bgcolor="secondary.container"
    pt={2}
    overflow="auto"
    maxHeight={320}
    borderRadius={theme => theme.shape.md.round}
  >
    <TextField
      fullWidth
      label="output"
      sx={{
        "& fieldset": {
          border: `unset !important`
        },
        "& label, & .Mui-focused": {
          color: theme => `${theme.palette.secondary.color} !important`
        }
      }}
      InputProps={{
        readOnly: true,
        sx: {
          bgcolor: "unset",
          typography: "bodyMd",
          color: "secondary.on.container"
        }
      }}
      {...props}
    />
  </Box>
);
const OutputWrapper = ({ children }) => (
  <Stack
    p={1}
    alignItems="center"
    justifyContent="center"
    overflow="hidden"
    bgcolor="secondary.container"
    sx={{ borderRadius: theme => theme.shape.sm }}
  >
    {children}
  </Stack>
);
const OutputDepthEstimation = ({ output, task }) => (
  <>
    <OutputImg output={output?.depth_png} />
    <OutputJSON output={output} task={task} />
  </>
);
//
// examples
//
function ExamplesInputImage({ images, setInput, loading }) {
  const [examples] = useState(
    images || [
      "https://ocean.si.edu/sites/default/files/styles/3_2_largest/public/2023-11/Screen_Shot_2018-04-16_at_1_42_56_PM.png.webp",
      "https://www.mccanndogs.com/cdn/shop/articles/living-with-cats-dogs-689902.jpg",
      "https://media.cnn.com/api/v1/images/stellar/prod/181002113456-01-golden-gate-bridge-restricted.jpg?q=w_1110,c_fill/f_webp"
    ]
  );

  return (
    <Stack direction="row" spacing={1}>
      {examples.map((img, index) => (
        <CardActionArea
          key={index}
          disabled={loading}
          onClick={() => setInput(img)}
        >
          <Box
            src={img}
            component="img"
            alt={`Example image ${index + 1}`}
            sx={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              objectFit: "cover",
              borderRadius: theme => theme.shape.sm,
              filter: loading ? "grayscale(1)" : undefined
            }}
          />
        </CardActionArea>
      ))}
    </Stack>
  );
}
const ExamplesInputQAVisual = ({ setInput, loading }) => (
  <ExamplesInputImage
    loading={loading}
    setInput={image => setInput(input => ({ ...input, image }))}
  />
);
const ExamplesInputQADocs = ({ setInput, loading }) => (
  <ExamplesInputImage
    loading={loading}
    images={[
      "https://templates.invoicehome.com/invoice-template-us-neat-750px.png",
      "https://t3.ftcdn.net/jpg/01/82/01/18/360_F_182011806_mxcDzt9ckBYbGpxAne8o73DbyDHpXOe9.jpg",
      "https://d3i71xaburhd42.cloudfront.net/f85f4431493ad1b3aa669c5371ca442f60f13c59/124-TableA.4-1.png"
    ]}
    setInput={image => setInput(input => ({ ...input, image }))}
  />
);
const ExamplesAudio = observer(function ExamplesAudio({ setInput, loading }) {
  const [examples] = useState([
    [
      "example 1",
      "https://huggingface.co/datasets/huggingfacejs/tasks/resolve/main/audio-classification/audio.wav"
    ],
    [
      "example 2",
      "https://huggingface.co/datasets/huggingfacejs/tasks/resolve/main/automatic-speech-recognition/input.flac"
    ]
  ]);
  const { device } = useStore();

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {examples.map(([label, audio], index) => (
        <Fragment key={index}>
          <ButtonOutlined
            label={label}
            size={device.isPhone ? "small" : undefined}
            disabled={loading}
            IconStart={AudioIcon}
            onClick={() => {
              const track = document.getElementById(label);
              const isPaused = track.currentTime === 0;

              for (const [label] of examples) {
                const audio = document.getElementById(label);

                audio.pause();
                audio.currentTime = 0;
              }

              if (isPaused) {
                track.play();
              } else {
                track.pause();
              }

              setInput(audio);
            }}
          />
          <audio id={label} src={audio} style={{ display: "none" }} />
        </Fragment>
      ))}
    </Stack>
  );
});
